import { IconComponent } from '@backstage/core-plugin-api';
import { Icon, SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function faIcon(icon: string): IconComponent {
  return props => <Icon className={`fa fa-${icon}`} {...props} />;
}

export const GiantSwarmIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 354.3 354.3">
    <path
      fill="currentColor"
      d="M346.6,131.7L266.2,28.9c-3.8-4.7-10.8-5.4-15.5-1.6c-0.8,0.6-1.5,1.4-2.1,2.2l-34.1,48.7c-11.2-6.3-23.9-9.5-36.7-9.4
	c-13.6-0.1-27,3.3-39,9.7l-33-47.6c-3.5-5-10.3-6.2-15.3-2.7c-0.8,0.6-1.6,1.3-2.2,2.1L7.7,133.1c-3.3,4.1,2.2,9.5,6.2,6l69.3-61.7
	c2-1.8,5.2-1.6,7,0.4c0,0,0,0.1,0.1,0.1l8.6,26.9c-48.3,11.9-77.9,60.7-66,109c9.3,37.9,42,65.6,81,68.4
	c1.2,17.3,11.1,35.9,42.8,47.2c1.4,0.5,3-0.3,3.5-1.7c0.3-0.9,0.1-1.8-0.4-2.6c-3.1-4.2-6.4-9.9-7.5-16.2
	c14.7,5.3,30.7,5.5,45.5,0.7c-1.3,5.6-3.8,10.9-7.4,15.5c-0.9,1.2-0.7,2.9,0.5,3.8c0.7,0.6,1.7,0.7,2.5,0.4
	c31.9-11.3,42.7-30,44.8-47.4c49.8-1.4,88.9-42.9,87.5-92.7c-1.2-41.2-30.1-76.3-70.3-85.3l8.8-27.6c1.8-2.1,4.9-2.3,7-0.6
	c0,0,0.1,0,0.1,0.1l69.3,61.7C344.4,141.1,350,135.8,346.6,131.7z M137.1,257.6c-22.4,13.7-55.6,0.2-74.3-30.1
	c-18.7-30.4-15.6-66.1,6.7-79.8s55.6-0.2,74.3,30.1C162.5,208.2,159.4,243.9,137.1,257.6z M286.6,147.3c22.7,13.9,25.9,50,7.1,80.6
	S241.3,272,218.6,258c-22.7-13.9-25.9-50-7.1-80.6C230.2,146.8,263.9,133.3,286.6,147.3z"
    />
    <path
      fill="currentColor"
      d="M84.5,231.2c-0.7-2.7-0.9-5.4-0.9-8.1c-0.1-2.7,0.2-5.3,0.9-7.9l24.1-10.3l-24.1-10.4c-0.6-2.6-0.9-5.3-0.9-8
      c-0.1-2.7,0.2-5.4,0.9-8l41.8,17.9c0.6,2.7,0.9,5.5,0.9,8.3c0,2.9-0.3,5.8-0.9,8.6L84.5,231.2z"
    />
    <path
      fill="currentColor"
      d="M223.1,213c0,0-0.9-2-0.9-7.5c0-5.4,0.9-7.5,0.9-7.5h55.5c0,0,1,2,1,7.5c0,5.4-1,7.5-1,7.5H223.1z"
    />
  </SvgIcon>
);

export const GrafanaIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 351 365">
    <path
      d="M342,161.2c-0.6-6.1-1.6-13.1-3.6-20.9c-2-7.7-5-16.2-9.4-25s-10.1-17.9-17.5-26.8c-2.9-3.5-6.1-6.9-9.5-10.2
    c5.1-20.3-6.2-37.9-6.2-37.9c-19.5-1.2-31.9,6.1-36.5,9.4c-0.8-0.3-1.5-0.7-2.3-1c-3.3-1.3-6.7-2.6-10.3-3.7
    c-3.5-1.1-7.1-2.1-10.8-3s-7.4-1.6-11.2-2.2c-0.7-0.1-1.3-0.2-2-0.3C214.2,12.4,189.8,1,189.8,1c-27.3,17.3-32.4,41.5-32.4,41.5
    s-0.1,0.5-0.3,1.4c-1.5,0.4-3,0.9-4.5,1.3c-2.1,0.6-4.2,1.4-6.2,2.2c-2.1,0.8-4.1,1.6-6.2,2.5c-4.1,1.8-8.2,3.8-12.2,6
    c-3.9,2.2-7.7,4.6-11.4,7.1c-0.5-0.2-1-0.4-1-0.4c-37.8-14.4-71.3,2.9-71.3,2.9C41.2,105.7,59.4,131,63,135.6
    c-0.9,2.5-1.7,5-2.5,7.5c-2.8,9.1-4.9,18.4-6.2,28.1c-0.2,1.4-0.4,2.8-0.5,4.2C18.8,192.7,8.5,228,8.5,228
    c29.1,33.5,63.1,35.6,63.1,35.6l0.1-0.1c4.3,7.7,9.3,15,14.9,21.9c2.4,2.9,4.8,5.6,7.4,8.3c-10.6,30.4,1.5,55.6,1.5,55.6
    c32.4,1.2,53.7-14.2,58.2-17.7c3.2,1.1,6.5,2.1,9.8,2.9c10,2.6,20.2,4.1,30.4,4.5c2.5,0.1,5.1,0.2,7.6,0.1h1.2h0.8h1.6l1.6-0.1v0.1
    c15.3,21.8,42.1,24.9,42.1,24.9c19.1-20.1,20.2-40.1,20.2-44.4l0,0c0,0,0-0.1,0-0.3c0-0.4,0-0.6,0-0.6l0,0c0-0.3,0-0.6,0-0.9
    c4-2.8,7.8-5.8,11.4-9.1c7.6-6.9,14.3-14.8,19.9-23.3c0.5-0.8,1-1.6,1.5-2.4c21.6,1.2,36.9-13.4,36.9-13.4
    c-3.6-22.5-16.4-33.5-19.1-35.6l0,0c0,0-0.1-0.1-0.3-0.2c-0.2-0.1-0.2-0.2-0.2-0.2l0,0c-0.1-0.1-0.3-0.2-0.5-0.3
    c0.1-1.4,0.2-2.7,0.3-4.1c0.2-2.4,0.2-4.9,0.2-7.3v-1.8v-0.9v-0.5c0-0.6,0-0.4,0-0.6l-0.1-1.5l-0.1-2c0-0.7-0.1-1.3-0.2-1.9
    c-0.1-0.6-0.1-1.3-0.2-1.9l-0.2-1.9L318,207c-0.4-2.5-0.8-4.9-1.4-7.4c-2.3-9.7-6.1-18.9-11-27.2c-5-8.3-11.2-15.6-18.3-21.8
    c-7-6.2-14.9-11.2-23.1-14.9c-8.3-3.7-16.9-6.1-25.5-7.2c-4.3-0.6-8.6-0.8-12.9-0.7h-1.6h-0.4c-0.1,0-0.6,0-0.5,0h-0.7l-1.6,0.1
    c-0.6,0-1.2,0.1-1.7,0.1c-2.2,0.2-4.4,0.5-6.5,0.9c-8.6,1.6-16.7,4.7-23.8,9s-13.3,9.6-18.3,15.6s-8.9,12.7-11.6,19.6
    c-2.7,6.9-4.2,14.1-4.6,21c-0.1,1.7-0.1,3.5-0.1,5.2c0,0.4,0,0.9,0,1.3l0.1,1.4c0.1,0.8,0.1,1.7,0.2,2.5c0.3,3.5,1,6.9,1.9,10.1
    c1.9,6.5,4.9,12.4,8.6,17.4c3.7,5,8.2,9.1,12.9,12.4c4.7,3.2,9.8,5.5,14.8,7s10,2.1,14.7,2.1c0.6,0,1.2,0,1.7,0c0.3,0,0.6,0,0.9,0
    c0.3,0,0.6,0,0.9-0.1c0.5,0,1-0.1,1.5-0.1c0.1,0,0.3,0,0.4-0.1l0.5-0.1c0.3,0,0.6-0.1,0.9-0.1c0.6-0.1,1.1-0.2,1.7-0.3
    c0.6-0.1,1.1-0.2,1.6-0.4c1.1-0.2,2.1-0.6,3.1-0.9c2-0.7,4-1.5,5.7-2.4c1.8-0.9,3.4-2,5-3c0.4-0.3,0.9-0.6,1.3-1
    c1.6-1.3,1.9-3.7,0.6-5.3c-1.1-1.4-3.1-1.8-4.7-0.9c-0.4,0.2-0.8,0.4-1.2,0.6c-1.4,0.7-2.8,1.3-4.3,1.8s-3.1,0.9-4.7,1.2
    c-0.8,0.1-1.6,0.2-2.5,0.3c-0.4,0-0.8,0.1-1.3,0.1c-0.4,0-0.9,0-1.2,0c-0.4,0-0.8,0-1.2,0c-0.5,0-1,0-1.5-0.1c0,0-0.3,0-0.1,0h-0.2
    h-0.3c-0.2,0-0.5,0-0.7-0.1c-0.5-0.1-0.9-0.1-1.4-0.2c-3.7-0.5-7.4-1.6-10.9-3.2c-3.6-1.6-7-3.8-10.1-6.6s-5.8-6.1-7.9-9.9
    c-2.1-3.8-3.6-8-4.3-12.4c-0.3-2.2-0.5-4.5-0.4-6.7c0-0.6,0.1-1.2,0.1-1.8c0,0.2,0-0.1,0-0.1v-0.2V201c0-0.3,0.1-0.6,0.1-0.9
    c0.1-1.2,0.3-2.4,0.5-3.6c1.7-9.6,6.5-19,13.9-26.1c1.9-1.8,3.9-3.4,6-4.9s4.4-2.8,6.8-3.9c2.4-1.1,4.8-2,7.4-2.7
    c2.5-0.7,5.1-1.1,7.8-1.4c1.3-0.1,2.6-0.2,4-0.2c0.4,0,0.6,0,0.9,0h1.1h0.7c0.3,0,0,0,0.1,0h0.3l1.1,0.1c2.9,0.2,5.7,0.6,8.5,1.3
    c5.6,1.2,11.1,3.3,16.2,6.1c10.2,5.7,18.9,14.5,24.2,25.1c2.7,5.3,4.6,11,5.5,16.9c0.2,1.5,0.4,3,0.5,4.5l0.1,1.1l0.1,1.1
    c0,0.4,0,0.8,0,1.1c0,0.4,0,0.8,0,1.1v1v1.1c0,0.7-0.1,1.9-0.1,2.6c-0.1,1.6-0.3,3.3-0.5,4.9c-0.2,1.6-0.5,3.2-0.8,4.8
    s-0.7,3.2-1.1,4.7c-0.8,3.1-1.8,6.2-3,9.3c-2.4,6-5.6,11.8-9.4,17.1c-7.7,10.6-18.2,19.2-30.2,24.7c-6,2.7-12.3,4.7-18.8,5.7
    c-3.2,0.6-6.5,0.9-9.8,1h-0.6h-0.5h-1.1h-1.6h-0.8c0.4,0-0.1,0-0.1,0h-0.3c-1.8,0-3.5-0.1-5.3-0.3c-7-0.5-13.9-1.8-20.7-3.7
    c-6.7-1.9-13.2-4.6-19.4-7.8c-12.3-6.6-23.4-15.6-32-26.5c-4.3-5.4-8.1-11.3-11.2-17.4c-3.1-6.1-5.6-12.6-7.4-19.1
    c-1.8-6.6-2.9-13.3-3.4-20.1l-0.1-1.3v-0.3v-0.3v-0.6v-1.1v-0.3v-0.4v-0.8V191v-0.3c0,0,0,0.1,0-0.1V190c0-0.8,0-1.7,0-2.5
    c0.1-3.3,0.4-6.8,0.8-10.2s1-6.9,1.7-10.3c0.7-3.4,1.5-6.8,2.5-10.2c1.9-6.7,4.3-13.2,7.1-19.3c5.7-12.2,13.1-23.1,22-31.8
    c2.2-2.2,4.5-4.2,6.9-6.2c2.4-1.9,4.9-3.7,7.5-5.4c2.5-1.7,5.2-3.2,7.9-4.6c1.3-0.7,2.7-1.4,4.1-2c0.7-0.3,1.4-0.6,2.1-0.9
    c0.7-0.3,1.4-0.6,2.1-0.9c2.8-1.2,5.7-2.2,8.7-3.1c0.7-0.2,1.5-0.4,2.2-0.7c0.7-0.2,1.5-0.4,2.2-0.6c1.5-0.4,3-0.8,4.5-1.1
    c0.7-0.2,1.5-0.3,2.3-0.5s1.5-0.3,2.3-0.5c0.8-0.1,1.5-0.3,2.3-0.4l1.1-0.2l1.2-0.2c0.8-0.1,1.5-0.2,2.3-0.3
    c0.9-0.1,1.7-0.2,2.6-0.3c0.7-0.1,1.9-0.2,2.6-0.3c0.5-0.1,1.1-0.1,1.6-0.2l1.1-0.1l0.5-0.1h0.6c0.9-0.1,1.7-0.1,2.6-0.2l1.3-0.1
    c0,0,0.5,0,0.1,0h0.3h0.6c0.7,0,1.5-0.1,2.2-0.1c2.9-0.1,5.9-0.1,8.8,0c5.8,0.2,11.5,0.9,17,1.9c11.1,2.1,21.5,5.6,31,10.3
    c9.5,4.6,17.9,10.3,25.3,16.5c0.5,0.4,0.9,0.8,1.4,1.2c0.4,0.4,0.9,0.8,1.3,1.2c0.9,0.8,1.7,1.6,2.6,2.4s1.7,1.6,2.5,2.4
    s1.6,1.6,2.4,2.5c3.1,3.3,6,6.6,8.6,10c5.2,6.7,9.4,13.5,12.7,19.9c0.2,0.4,0.4,0.8,0.6,1.2s0.4,0.8,0.6,1.2
    c0.4,0.8,0.8,1.6,1.1,2.4c0.4,0.8,0.7,1.5,1.1,2.3c0.3,0.8,0.7,1.5,1,2.3c1.2,3,2.4,5.9,3.3,8.6c1.5,4.4,2.6,8.3,3.5,11.7
    c0.3,1.4,1.6,2.3,3,2.1c1.5-0.1,2.6-1.3,2.6-2.8C342.6,170.4,342.5,166.1,342,161.2z"
    />
  </SvgIcon>
);
