/**
 * This file was automatically generated, PLEASE DO NOT MODIFY IT BY HAND.
 */

export const ProviderConfigApiVersion = 'aws.upbound.io/v1beta1';

export const ProviderConfigGVK = {
  apiVersion: 'v1beta1',
  group: 'aws.upbound.io',
  plural: 'providerconfigs',
};
