/**
 * This file was automatically generated, PLEASE DO NOT MODIFY IT BY HAND.
 */

export const OrganizationApiVersion = 'security.giantswarm.io/v1alpha1';

export const OrganizationGVK = {
  apiVersion: 'v1alpha1',
  group: 'security.giantswarm.io',
  plural: 'organizations',
};
